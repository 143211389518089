// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-talk-js": () => import("./../src/templates/talk.js" /* webpackChunkName: "component---src-templates-talk-js" */),
  "component---src-templates-interactive-js": () => import("./../src/templates/interactive.js" /* webpackChunkName: "component---src-templates-interactive-js" */),
  "component---src-templates-speaker-js": () => import("./../src/templates/speaker.js" /* webpackChunkName: "component---src-templates-speaker-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backup-js": () => import("./../src/pages/backup.js" /* webpackChunkName: "component---src-pages-backup-js" */),
  "component---src-pages-closed-betas-js": () => import("./../src/pages/closed-betas.js" /* webpackChunkName: "component---src-pages-closed-betas-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-continuous-improvement-js": () => import("./../src/pages/continuous-improvement.js" /* webpackChunkName: "component---src-pages-continuous-improvement-js" */),
  "component---src-pages-digital-js": () => import("./../src/pages/digital.js" /* webpackChunkName: "component---src-pages-digital-js" */),
  "component---src-pages-guide-js": () => import("./../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactives-js": () => import("./../src/pages/interactives.js" /* webpackChunkName: "component---src-pages-interactives-js" */),
  "component---src-pages-moderation-guide-js": () => import("./../src/pages/moderation-guide.js" /* webpackChunkName: "component---src-pages-moderation-guide-js" */),
  "component---src-pages-partners-js": () => import("./../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-schedule-js": () => import("./../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speaker-guide-js": () => import("./../src/pages/speaker-guide.js" /* webpackChunkName: "component---src-pages-speaker-guide-js" */),
  "component---src-pages-speakers-js": () => import("./../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-talks-js": () => import("./../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */)
}

